import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowsAlt,
  faCamera,
  faClock,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss',
})
export class FeaturesComponent {
  countdownIcon = faClock;
  pictureIcon = faImage;
  cameraIcon = faCamera;
  arrowIcon = faArrowsAlt;
}
