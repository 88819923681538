<div
  class="modal fade"
  id="newCountdown"
  tabindex="-1"
  role="dialog"
  aria-labelledby="newCountdownLabel"
  style="display: none"
  aria-hidden="true"
  #newCountdown
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="newCountdownLabel">
          {{ "webapp.add.title" | translate }}
        </h1>
        <button
          (click)="closeModal()"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form method="get">
          <div class="form-group">
            <label for="titelInput">
              {{ "webapp.add.inputTitle" | translate }}
            </label>
            <input
              type="text"
              name="title"
              class="form-control"
              id="titelInput"
              placeholder="{{
                'webapp.add.inputTitlePlaceholder' | translate
              }} {{ currentYear }}"
              [(ngModel)]="countdown.title"
            />
          </div>
          <div class="form-group">
            <label for="datumInput">
              {{ "webapp.add.inputDate" | translate }}
            </label>
            <input
              type="date"
              name="date"
              class="form-control"
              id="datumInput"
              (ngModelChange)="onDateChange($event)"
              [ngModel]="countdown.date | date : 'yyyy-MM-dd'"
            />
          </div>
          <div class="form-group">
            <label for="uhrzeitInput">
              {{ "webapp.add.inputTime" | translate }}
            </label>
            <input
              type="time"
              name="time"
              class="form-control"
              id="uhrzeitInput"
              [(ngModel)]="countdown.time"
            />
          </div>
          <div class="form-group">
            <label for="bildInput">
              {{ "webapp.add.inputBackground" | translate }}
            </label>
            <br />

            <div class="images">
              <div class="image">
                <input
                  [checked]="this.countdown.image == 1"
                  id="image1"
                  class="cc-selector-2"
                  type="radio"
                  name="image"
                  value="1"
                />
                <label class="drinkcard-cc image1" for="image1"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 2"
                  class="cc-selector-2"
                  id="image2"
                  type="radio"
                  name="image"
                  value="2"
                />
                <label class="drinkcard-cc image2" for="image2"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 3"
                  id="image3"
                  class="cc-selector-2"
                  type="radio"
                  name="image"
                  value="3"
                />
                <label class="drinkcard-cc image3" for="image3"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 4"
                  class="cc-selector-2"
                  id="image4"
                  type="radio"
                  name="image"
                  value="4"
                />
                <label class="drinkcard-cc image4" for="image4"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 5"
                  id="image5"
                  class="cc-selector-2"
                  type="radio"
                  name="image"
                  value="5"
                />
                <label class="drinkcard-cc image5" for="image5"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 6"
                  class="cc-selector-2"
                  id="image6"
                  type="radio"
                  name="image"
                  value="6"
                />
                <label class="drinkcard-cc image6" for="image6"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 7"
                  id="image7"
                  class="cc-selector-2"
                  type="radio"
                  name="image"
                  value="7"
                />
                <label class="drinkcard-cc image7" for="image7"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 8"
                  class="cc-selector-2"
                  id="image8"
                  type="radio"
                  name="image"
                  value="8"
                />
                <label class="drinkcard-cc image8" for="image8"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 9"
                  class="cc-selector-2"
                  id="image9"
                  type="radio"
                  name="image"
                  value="9"
                />
                <label class="drinkcard-cc image9" for="image9"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 10"
                  class="cc-selector-2"
                  id="image10"
                  type="radio"
                  name="image"
                  value="10"
                />
                <label class="drinkcard-cc image10" for="image10"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 11"
                  class="cc-selector-2"
                  id="image11"
                  type="radio"
                  name="image"
                  value="11"
                />
                <label class="drinkcard-cc image11" for="image11"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 12"
                  class="cc-selector-2"
                  id="image12"
                  type="radio"
                  name="image"
                  value="12"
                />
                <label class="drinkcard-cc image12" for="image12"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 13"
                  class="cc-selector-2"
                  id="image13"
                  type="radio"
                  name="image"
                  value="13"
                />
                <label class="drinkcard-cc image13" for="image13"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 14"
                  class="cc-selector-2"
                  id="image14"
                  type="radio"
                  name="image"
                  value="14"
                />
                <label class="drinkcard-cc image14" for="image14"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 15"
                  class="cc-selector-2"
                  id="image15"
                  type="radio"
                  name="image"
                  value="15"
                />
                <label class="drinkcard-cc image15" for="image15"></label>
              </div>

              <div class="image">
                <input
                  [checked]="this.countdown.image == 16"
                  class="cc-selector-2"
                  id="image16"
                  type="radio"
                  name="image"
                  value="16"
                />
                <label class="drinkcard-cc image16" for="image16"></label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          {{ "webapp.buttons.cancel" | translate }}
        </button>
        <button type="submit" (click)="saveModal()" class="btn btn-primary">
          {{ "webapp.buttons.save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="toast text-bg-danger"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
  #errorToast
>
  <div class="toast-header">
    <strong class="me-auto">{{ "webapp.error.title" | translate }}</strong>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="toast"
      aria-label="Close"
    ></button>
  </div>
  <div class="toast-body">{{ "webapp.error.text" | translate }}</div>
</div>
