<div class="overlay" id="overlay" (click)="closeNav()"></div>

<nav
  id="nav"
  [ngClass]="{ subpage: !isHome }"
  class="custom-menu navbar navbar-inverse fixed-top"
>
  <div class="container">
    <div class="col-md-6 col-xs-6 craft-bar">
      <a routerLink="/"
        ><img src="assets/logo.png" alt="Logo {{ 'meta.altTag' | translate }}"
      /></a>
    </div>
    <div class="col-lg-6 col-xs-6 col-sm-6 col-md-6 craft-bar text-end">
      <div class="main" id="main" style="margin-left: 0px">
        <a (click)="openNav()">
          <img
            class="zoom-act"
            src="assets/menu.png"
            alt="Menu Icon Urlaubscountdown"
          />
        </a>
      </div>
    </div>
  </div>
</nav>

<div id="mySidenav" class="sidenav text-center">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">×</a><br />
  @if(!isHome){
  <a href="/">Home</a>
  <hr />
  }
  <a href="/webapp">ONLINE WEBCOUNTDOWN</a>
  <hr />
  <a
    target="_blank"
    href="https://itunes.apple.com/de/app/countdown-zum-urlaub-holiday/id1400997808?mt=8&amp;at=11lNGX"
    >Download Apple iOS</a
  >
  <hr />
  <a
    target="_blank"
    href="https://play.google.com/store/apps/details?id=de.kisimedia.HolidayCountdown"
    >Download Google PlayStore</a
  >
  <hr />
  <a class="lang" href="#de" (click)="changeLang('de')">DE</a> /
  <a class="lang" href="#en" (click)="changeLang('en')">EN</a>
  <hr />
</div>
