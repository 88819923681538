import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Countdown } from '../../model/countdown.modal';
import { CountdownService } from '../../services/countdown.service';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-modal',
  standalone: true,
  imports: [TranslateModule, FormsModule, DatePipe],
  templateUrl: './add-modal.component.html',
  styleUrl: './add-modal.component.scss',
})
export class AddModalComponent {
  @ViewChild('newCountdown') newCountdown: ElementRef | undefined;
  newCountdownModal: bootstrap.Modal | undefined;

  @ViewChild('errorToast') errorToast: ElementRef | undefined;

  countdown: Countdown = {};

  currentYear = new Date().getFullYear();

  bootstrap: any;

  constructor(
    private countdownService: CountdownService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!this.countdownService.countdown().init) {
      this.countdown = this.countdownService.countdown();
    } else {
      this.countdown = {
        date: new Date(),
        time: new Date().toLocaleTimeString().slice(0, 5),
        init: false,
        image: 1,
      };
    }
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.bootstrap = await import('bootstrap');
    }
  }

  saveModal() {
    if (!this.countdown.title || !this.countdown.date || !this.countdown.time) {
      this.showErrorToast();
      return;
    }
    this.findCheckedRadioInput();
    const date = new Date(this.countdown.date);
    this.countdown.date = date;
    this.countdownService.createCountdown(this.countdown).then((res) => {
      if (res.shortUrl) {
        this.closeModal();
        this.router.navigate([`/${res.shortUrl}`]);
      }
    });
  }

  findCheckedRadioInput() {
    const radioInputs = this.document.querySelectorAll(
      'input[type="radio"]:checked'
    );
    if (radioInputs.length > 0) {
      const radioInput = radioInputs[0] as HTMLInputElement;
      this.countdown.image = parseInt(radioInput.value);
    }
  }

  showErrorToast() {
    if (this.errorToast) {
      const errorToast = this.bootstrap.Toast.getOrCreateInstance(
        this.errorToast.nativeElement
      );
      errorToast.show();
    } else {
      console.error('this.errorToast is undefined');
    }
  }

  onDateChange(event: any) {
    this.countdown.date = event;
  }

  closeModal() {
    if (this.newCountdownModal) {
      this.newCountdownModal.hide();
    } else {
      console.error('this.newCountdownModal is undefined');
    }
  }
}
