<header>
  <div class="blur">
    <div class="container">
      <div class="row head-body">
        <div class="col-lg-7 head-items">
          <h1 style="text-transform: uppercase">
            {{ "header.title" | translate }}
          </h1>
          <p class="fun-text">
            {{ "header.subtitle" | translate }}
          </p>
          <a
            href="https://itunes.apple.com/de/app/countdown-zum-urlaub-holiday/id1400997808?mt=8&amp;at=11lNGX"
            class="btn craft-btn"
          >
            <fa-icon [icon]="appleIcon"></fa-icon>
            <div class="craft-btn-content">
              <h6>{{ "buttons.download" | translate }}</h6>
              <h4>Apple iOS</h4>
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=de.kisimedia.HolidayCountdown"
            class="btn craft-btn"
          >
            <fa-icon [icon]="androidIcon"></fa-icon>
            <div class="craft-btn-content">
              <h6>{{ "buttons.download" | translate }}</h6>
              <h4>Android</h4>
            </div>
          </a>
          <a href="/webapp" class="btn craft-btn">
            <fa-icon [icon]="desktopIcon"></fa-icon>
            <div class="craft-btn-content">
              <h6>Webapp</h6>
              <h4>Online</h4>
            </div>
          </a>
        </div>
        <div class="col-lg-4">
          <img
            class="img-responsive"
            src="./../../../assets/header-iphone-mockup.png"
            alt="Screenshot 1
          {{ 'meta.altTag' | translate }}"
          />
        </div>
      </div>
    </div>
  </div>
</header>
