import { DOCUMENT, isPlatformBrowser, NgClass } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [NgClass, TranslateModule],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
})
export class NavComponent {
  @Input() isHome: boolean = false;

  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  openNav() {
    // add class open to #mySidenav and #overlay
    this.document.getElementById('mySidenav')?.classList.add('open');
    this.document.getElementById('overlay')?.classList.add('open');
  }

  closeNav() {
    // remove class open from #mySidenav and #overlay
    this.document.getElementById('mySidenav')?.classList.remove('open');
    this.document.getElementById('overlay')?.classList.remove('open');
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('lang', lang);
    }
    this.closeNav();
  }
}
