<div class="overlay" id="overlay" (click)="closeNav()"></div>

<nav id="nav" class="custom-menu navbar navbar-inverse fixed-top">
  <div class="container">
    <div
      class="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 col-xxl-6 craft-bar"
    >
      <a class="btn craft-btn" (click)="openAddCountdown()">
        <fa-icon [icon]="plusIcon"></fa-icon>
        {{ "webapp.newCountdown" | translate }}
      </a>
      <a class="btn craft-btn" (click)="openShareCountdown()">
        <fa-icon [icon]="shareIcon"></fa-icon>
        {{ "webapp.shareCountdown" | translate }}
      </a>
    </div>
    <div
      class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6 col-xl-6 col-xxl-6 craft-bar text-end"
    >
      <div class="main" id="main" style="margin-left: 0px">
        <a (click)="openNav()">
          <img
            class="zoom-act"
            src="./../../../assets/menu.png"
            alt="Menu Icon {{ 'meta.altTag' | translate }}"
          />
        </a>
      </div>
    </div>
  </div>
</nav>

<div id="mySidenav" class="sidenav text-center">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">×</a><br />
  <a href="/">{{ "webapp.nav.start" | translate }}</a>
  <hr />
  <a href="/webapp">{{ "webapp.nav.webapp" | translate }}</a>
  <hr />
  @if (currentLang === 'de'){
  <a
    target="_blank"
    href="https://www.amazon.de/gp/search?ie=UTF8&tag=news0803-21&linkCode=ur2&linkId=6f3a8ae39b44d93679ba0efc461e8593&camp=1638&creative=6742&index=aps&keywords=Urlaub Gadgets"
    >{{ "webapp.nav.gadgets" | translate }}</a
  >
  <hr />
  }
  <a
    target="_blank"
    href="https://itunes.apple.com/de/app/countdown-zum-urlaub-holiday/id1400997808?mt=8&amp;at=11lNGX"
    >{{ "webapp.nav.ios" | translate }}</a
  >
  <hr />
  <a
    target="_blank"
    href="https://play.google.com/store/apps/details?id=de.kisimedia.HolidayCountdown"
    >{{ "webapp.nav.android" | translate }}</a
  >
  <hr />
  <a class="lang" (click)="changeLang('de')">DE</a> /
  <a class="lang" (click)="changeLang('en')">EN</a>
  <hr />
</div>

<app-add-modal></app-add-modal>
<app-share-modal></app-share-modal>
