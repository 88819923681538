<div class="container feature-section">
  <div class="row">
    <!-- Individual block is an individual section one feature -->
    <div class="col-lg-3 col-md-6 feature-block">
      <div class="icon-circle">
        <!-- Only the icon and the circle  -->
        <fa-icon [icon]="countdownIcon"></fa-icon>
      </div>
      <h3>{{ "feature.countdown.title" | translate }}</h3>
      <p>{{ "feature.countdown.desc" | translate }}</p>
    </div>
    <div class="col-lg-3 col-md-6 feature-block">
      <div class="icon-circle">
        <!-- Only the icon and the circle  -->
        <fa-icon [icon]="pictureIcon"></fa-icon>
      </div>
      <h3>{{ "feature.images.title" | translate }}</h3>
      <p>{{ "feature.images.desc" | translate }}</p>
    </div>
    <div class="col-lg-3 col-md-6 feature-block">
      <div class="icon-circle">
        <!-- Only the icon and the circle  -->
        <fa-icon [icon]="cameraIcon"></fa-icon>
      </div>
      <h3>{{ "feature.camera.title" | translate }}</h3>
      <p>{{ "feature.camera.desc" | translate }}</p>
    </div>
    <div class="col-lg-3 col-md-6 feature-block">
      <div class="icon-circle">
        <!-- Only the icon and the circle  -->
        <fa-icon [icon]="arrowIcon"></fa-icon>
      </div>
      <h3>{{ "feature.fullsize.title" | translate }}</h3>
      <p>{{ "feature.fullsize.desc" | translate }}</p>
    </div>
  </div>
</div>
