import { Component } from '@angular/core';
import { AppNavComponent } from './app-nav/app-nav.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CtaComponent } from './cta/cta.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { CountdownService } from '../services/countdown.service';

@Component({
  selector: 'app-webapp',
  standalone: true,
  imports: [AppNavComponent, CountdownComponent, CtaComponent, FooterComponent],
  templateUrl: './webapp.component.html',
  styleUrl: './webapp.component.scss',
})
export class WebappComponent {
  constructor(
    private route: ActivatedRoute,
    private countdownService: CountdownService,
    private translate: TranslateService,
    private title: Title
  ) {
    this.route.params.subscribe((params) => {
      if (params['shortUrl']) {
        this.countdownService
          .getCountdown(params['shortUrl'])
          .then((res: any) => {
            if (res) {
              console.log(res);
              this.countdownService.countdown.set(res);
            }
          });
      }
    });

    translate.get('meta.title').subscribe((res: string) => {
      const currentLang = translate.currentLang;
      if (currentLang === 'de') {
        title.setTitle('Online Urlaubscountdown Webcountdown - ' + res);
      } else {
        title.setTitle('Online Holiday Countdown Webcountdown - ' + res);
      }
    });
  }

  ngOnDestroy(): void {
    this.translate.get('meta.title').subscribe((res: string) => {
      this.title.setTitle(res);
    });
  }
}
