import { NgModule, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import * as translationEn from './../../assets/i18n/en.json';
import * as translationDe from './../../assets/i18n/de.json';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

const TRANSLATIONS: { en: any; de: any } = {
  en: translationEn,
  de: translationDe,
};

export class JSONModuleLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang as keyof typeof TRANSLATIONS]);
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: JSONModuleLoader,
        deps: [HttpClient, PLATFORM_ID],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(translate: TranslateService) {
    const currentLang = translate.currentLang;
    let browserLang = translate.getBrowserLang();
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang(currentLang ?? browserLang ?? environment.lang);
    browserLang = translate.getBrowserLang() ?? environment.lang;
    translate.use(
      RegExp(/en|de/).exec(browserLang) ? browserLang : environment.lang
    );
  }
}
