<section id="explore" class="section explore-mindcraft">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-xs-6 col-6 zoom-act">
        <img
          class="img-responsive"
          src="./../../../assets/screen1.png"
          alt="{{ 'meta.altTag' | translate }} Strand/Beach"
        /><br />
      </div>
      <div class="col-lg-3 col-md-6 col-xs-6 col-6 zoom-act">
        <img
          class="img-responsive"
          src="./../../../assets/screen2.png"
          alt="{{ 'meta.altTag' | translate }} Campen"
        /><br />
      </div>
      <div class="col-lg-6 col-md-12 explore">
        <h2>{{ "description.title" | translate }}</h2>
        <p innerHTML="{{ 'description.text' | translate }}"></p>
        <h3>{{ "description.functions.title" | translate }}</h3>
        <ul>
          <li>
            {{ "description.functions.bullets.bullet1" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet2" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet3" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet4" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet5" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet6" | translate }}
          </li>
          <li>
            {{ "description.functions.bullets.bullet7" | translate }}
          </li>
        </ul>
        <h3>{{ "description.hiddenCosts.title" | translate }}</h3>
        <ul>
          <li>{{ "description.hiddenCosts.bullets.bullet1" | translate }}</li>
          <li>{{ "description.hiddenCosts.bullets.bullet2" | translate }}</li>
        </ul>
        <p></p>
        <a
          href="https://itunes.apple.com/de/app/countdown-zum-urlaub-holiday/id1400997808?mt=8&amp;at=11lNGX"
          class="btn craft-btn"
        >
          <fa-icon [icon]="appleIcon"></fa-icon>
          <div class="craft-btn-content">
            <h6>{{ "buttons.download" | translate }}</h6>
            <h4>Apple iOS</h4>
          </div>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=de.kisimedia.HolidayCountdown"
          class="btn craft-btn"
        >
          <fa-icon [icon]="androidIcon"></fa-icon>
          <div class="craft-btn-content">
            <h6>{{ "buttons.download" | translate }}</h6>
            <h4>Android</h4>
          </div>
        </a>
        <a href="/webapp" class="btn craft-btn">
          <fa-icon [icon]="desktopIcon"></fa-icon>
          <div class="craft-btn-content">
            <h6>Webapp</h6>
            <h4>Online</h4>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
