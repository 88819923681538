<div class="cta-content">
  <div class="container">
    <h2>{{ "webapp.cta.title" | translate }} App</h2>
    <a
      href="https://itunes.apple.com/de/app/countdown-zum-urlaub-holiday/id1400997808?mt=8&amp;at=11lNGX"
      class="btn craft-btn"
    >
      <fa-icon [icon]="appleIcon"></fa-icon>
      <div class="craft-btn-content">
        <h6>{{ "buttons.download" | translate }}</h6>
        <h4>Apple iOS</h4>
      </div>
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=de.kisimedia.HolidayCountdown"
      class="btn craft-btn"
    >
      <fa-icon [icon]="androidIcon"></fa-icon>
      <div class="craft-btn-content">
        <h6>{{ "buttons.download" | translate }}</h6>
        <h4>Android</h4>
      </div>
    </a>
  </div>
</div>
